import {
  apiGet, apiPost, apiStore, generateFormData,
} from '../../../modules/apiHelper';
import resolve from '../../../modules/api/resolve';
import {
  invalideDate, isStaleData, objectIsValid, now,
} from '../../../modules/dataHelper';

export default {
  async getCompanies(context, refresh = false) {
    const request = resolve(apiGet('company', 0, null, refresh));

    // wait for the xhr
    const result = await request;
    return (result || {}).company || false;
  },

  async getCompany(context, [companyid, refresh = false]) {
    if (!companyid) return false;
    // eslint-disable-next-line no-underscore-dangle
    const _company = await apiStore.getItem(`company/${companyid}`) || [invalideDate, {}];

    if (!refresh && !isStaleData(_company[0]) && objectIsValid(_company[1])) return _company[1];

    if (refresh) {
      apiStore.removeItem(`company/${companyid}`);
    }

    // ! returns an array of length = 1
    const company = await resolve(apiPost('company_data', generateFormData({ companyid }), 7));

    if (!company) return false;

    apiStore.setItem(`company/${companyid}`, [now(), company.company_data[0]]);

    return company.company_data[0];
  },
};
